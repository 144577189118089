<!-- 关于我们 -->
<template>
  <div class="about">
    <div class="title gn-full-x gn-flex-center">关于我们</div>
    <div class="tabs">
      <ul>
        <li v-for="(item,index) in tabs" :key="index" :class="index == tabKey ? 'active_tabs': ''" @click="handleCheck(index)">{{item.title}}</li>
      </ul>
    </div>
    <!-- 企业简介 -->
    <div class="card" ref="abstract">
      <span>企业简介</span>
    </div>

    <!-- pc端 -->
    <div class="abstract pc-abstract">
      <div class="abstract_content">
        <div class="tip">
          <img src="@/assets/images/companyProfile/tip.png" alt="">
        </div>
        <div class="abstract_info">
          <div class="abstract_info_header">
            <div style="display: flex;">
              <div class="abstract_info_img"></div>
              <div class="header">
                <div class="title">广州市广农数链信息科技有限公司</div>
                <div class="header-main">
                  <p>广州市广农数链信息科技有限公司，为广府数字（深圳）股份公司全资子公司，成立于2017年2月，实缴注册资本1000万元，是一家从事互联网平台软件开发及其推广应用的国家高新技术企业。</p>
                </div>
              </div>
            </div>
          </div>
          <div class="abstract_info_footer">
            <div class="footer_left">
              <div>公司致力于通过移动互联网和大数据赋能传统供应链产业转型升级，提供智慧供应链管理平台和平台孵化的一站式解决方案，助力中小企业搭上数字化发展的时代快车。</div>
              <div>公司专注于行业分销渠道的数字化建设，着眼全国专业批发市场和生产商、经销商，全力打造一个覆盖生产-流通-销售的全国一体化网络平台；着力解决流通领域的中小企业数字化升级改造的难点和痛点。</div>
            </div>
            <img class="footer_img" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242116/17132556800119721151" alt="">
          </div>
          <!-- <div class="abstract_info_left">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20232912/16970957690501220778" alt="">
          </div>
          <div class="abstract_info_right">
            <div class="abstract_info_right_title">
              广州市广农数链信息科技有限公司
            </div>
            <div class="abstract_info_right_text">
              <p>2017年2月成立</p>
              <p>广府数字（深圳）股份公司全资子公司</p>
              <p>下属企业：广东省数字羽田科技有限公司</p>
              <p>实缴注册资金：1000万</p>
            </div>
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20233512/16970961381022166710" alt="">
            <div class="abstract_info_right_text" style="text-indent: 2em;">
              广州市广农数链信息科技有限公司是一家信息技术开发与应用服务商，专注农资产业供应链项目投资和运营管理，国家高新技术企业。
            </div>
            <div class="abstract_info_right_title1">
              核心优势
            </div>
            <div class="abstract_info_right_text" style="text-indent: 2em;">
              把数字化理念、互联网优势与传统流通渠道运作相结合，搭建以企业用户为中心的云、链、圈、端、数字物连配送一体化系统。
            </div>
          </div> -->
        </div>
        <div class="abstract_img">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20234812/16970969323182113681" alt="">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20234812/16970969355543121892" alt="">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20234812/16970969393152243124" alt="">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20234912/16970969449846966985" alt="">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20234912/16970969478570913339" alt="">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20234912/16970969505697493077" alt="">
        </div>
        <div class="abstract_ul">
          <ul>
            <li>• 互联网科技国家高新技术企业（二届）</li>
            <li>• 广东省农产品流通协会常务理事单位</li>
            <li>• 广州市农业肥料应用协会会长单位</li>
            <li>• 中国物流与采购联合会农产品供应链分会会员单位</li>
            <li>• 华夏职业学院产教实习实训基地</li>
          </ul>
          <ul>
            <li>• 2022年广东省创新型中小企业</li>
            <li>• 2021年广州市农业龙头企业</li>
            <li>• 广州市肉菜市场行业协会副会长单位</li>
            <li>• AAA重合同守信用企业</li>
            <li>• 质量管理体系认证（ISO9001）</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="mobile-abstract">
      <div class="abstract_content">
        <div class="abstract_content_title">广州市广农数链信息科技有限公司</div>
        <div class="abstract_content_text">
          广州市广农数链信息科技有限公司，为广府数字（深圳）股份公司全资子公司，成立于2017年2月，实缴注册资本1000万元，是一家从事互联网平台软件开发及其推广应用的国家高新技术企业。
        </div>
        <div class="abstract_content_img">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242116/17132556800119721151">
        </div>
        <div style="margin-top: 30px">
          <div class="abstract_content_text">
            公司致力于通过移动互联网和大数据赋能传统供应链产业转型升级，提供智慧供应链管理平台和平台孵化的一站式解决方案，助力中小企业搭上数字化发展的时代快车。          </div>
          <div class="abstract_content_text">
            公司专注于行业分销渠道的数字化建设，着眼全国专业批发市场和生产商、经销商，全力打造一个覆盖生产-流通-销售的全国一体化网络平台；着力解决流通领域的中小企业数字化升级改造的难点和痛点。          </div>

        </div>
        <div class="imgs gn-full-x gn-flex">
          <div class="gn_image1">
            <img class="gn-full" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/2828d4800030427a8dac521ce786c98e.png">
          </div>
          <div class="gn_image2">
            <img class="gn-full" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/b030e0c7d1064696a784130626e77c98.png">
          </div>
          <div class="gn_image3">
            <img class="gn-full" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/7fb320de95eb4095b9fe12f165cafdfc.png">
          </div>
          <!-- <div class="gn-flex-1">
            <img class="gn-full" src="@/assets/images/companyProfile/about-mobile-info1.png">
          </div>
          <div class="gn-flex-1">
            <img class="gn-full" src="@/assets/images/companyProfile/about-mobile-info2.png">
          </div>
          <div class="gn-flex-1">
            <img class="gn-full" src="@/assets/images/companyProfile/about-mobile-info3.png">
          </div> -->
        </div>
        <div class="imgs gn-full-x gn-flex" style="padding: 0 10px;">
          <div class="gn_image4" style="margin-right: 15px;">
            <img class="gn-full" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/aef4f9388b454d76ae4d909e11823e5f.png">
          </div>
          <div class="gn_image5" style="margin-right: 15px;">
            <img class="gn-full" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/0f627c13f7b5419cbfffe9b5dc507e4f.png">
          </div>
          <div class="gn_image6" style="margin-right: 15px;">
            <img class="gn-full" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/7d07ce13f37d478ba60004dff47c3fd6.png">
          </div>
        </div>

        <!-- <div class="abstract_content_text">
          公司研发的“宝秤农资”是一个专注于农资流通领域的产业电商平台，平台创造性地建立了全国一体化的农资网络流通体系和供应链渠道管理系统，连通了全国2000多个县的十万经销商，以及县\镇\村百万终端门店，覆盖了全国七千家农药\肥料厂商；通过私域扁平化管理手段，实现价格保密，千人千价，彻底解决窜货顽疾；针对不同客户的交易需求，支持灵活多样的线上结算方式，在不改变商家原有结算方式的同时，极大强化了智能化结算能力。该项目是广东省农业农村厅农资平台推荐软件。
        </div>
        <div class="end-text">
          公司成立五年来，只做一件事：智慧供应链一站式解决方案。因为专一，所以专业。
        </div> -->
        <div class="abstract_content_text" style="text-indent:0em;padding-bottom:20px ;">
          • 互联网科技国家高新技术企业（二届）<br/>
          • 广东省农产品流通协会常务理事单位<br/>
          • 广州市农业肥料应用协会会长单位<br/>
          • 中国物流与采购联合会农产品供应链分会会员<br/>
          <span>&nbsp;&nbsp;&nbsp;单位</span><br/>
          • 华夏职业学院产教实习实训基地<br/>
          • 2022年广东省创新型中小企业<br/>
          • 2021年广州市农业龙头企业<br/>
          • 广州市肉菜市场行业协会副会长单位<br/>
          • AAA重合同守信用企业<br/>
          • 质量管理体系认证（ISO9001）<br/>
        </div>
      </div>
    </div>

    <!-- 企业文化 -->
    <div class="card" ref="culture">
      <span>企业文化</span>
    </div>
    <!-- pc端 -->
    <div class="culture">
      <div class="culture_content">
        <div class="top">
          <ul>
            <li>
              <div class="title">宗旨</div>
              <div class="text">让农业生产更高效</div>
            </li>
            <li>
              <img src="@/assets/images/companyProfile/culture_1.png" alt="">
            </li>
            <li>
              <img src="@/assets/images/companyProfile/culture_icon.png" alt="">
            </li>
            <li>
              <div class="title">愿景</div>
              <div class="text">数字通千村，城乡共富裕</div>
            </li>
          </ul>
        </div>
        <div class="bottom">
          <ul>
            <li>
              <div class="title">理念</div>
              <div class="text">科技兴农，奋楫笃行</div>
            </li>
            <li>
              <img src="@/assets/images/companyProfile/culture_2.png" alt="">
            </li>
            <li>
              <div class="title">价值观</div>
              <div class="text">链通市旺•融通共生</div>
            </li>
            <li>
              <div class="title">使命</div>
              <div class="text">让美丽乡村更富饶</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="mobile-culture">
      <div
        class="center_item mobile-center_item"
        v-for="(item, index) in publishData['12']"
        :key="index + new Date().getTime()"
      >
        <div class="img gn-flex-center">
          <img :src="item.img" style="width: 63px; height: 63px" />
        </div>
        <div>
          <div class="btn gn-flex-center"
          :class="index === 1 ? 'color1' : (index === 2 ? 'color2' : (index === 3 ? 'color3' : (index === 4 ? 'color4' : 'color0')))">
            {{ item.name }}
          </div>
          <div class="text">{{ item.introduce }}</div>
        </div>
      </div>
      <div style="border-bottom: 1px solid #f1f1f1; margin-top: 30px"></div>
    </div>

    <!-- 发展历程 -->
    <div class="card history-card" ref="course" v-if="publishData['13'] && publishData['13'].length">
      <span>发展历程</span>
    </div>
    <div class="course">
      <div class="course_content">
        <ul v-if="publishData['13'] && publishData['13'].length">
          <li v-for="(item, index) in publishData['13']" :key="index">
            <div class="icon"></div>
            <div class="year">{{item.date}}</div>

            <div class="title pc">{{item.name}}</div>
            <div class="text pc">{{item.introduce}}</div>

            <div style='display: none' class="mobile">
              <div class="title">{{item.name}}</div>
              <div class="text">{{item.introduce}}</div>
            </div>
          </li>
        </ul>
        <div v-if="$isMobile" style="border-bottom: 1px solid #f1f1f1; margin-top: 40px"></div>
      </div>
    </div>
    <!-- 获得荣誉 -->
    <div class="card" style="margin-bottom: 0px" ref="achievements" v-if="publishData['14'] && publishData['14'].length">
      <span>获得荣誉</span>
    </div>
    <div class="achievements" v-if="publishData['14'] && publishData['14'].length">
      <swiper class="swiper" ref="mySwiper" :key="updateKey" :options="swiperOptionOther">
        <swiper-slide
          class="achievements-list"
          v-for="(item, index) in publishData['14']"
          :key="index"
        >
          <div class="achievements-item">
            <div class="img">
              <img :src="item.img" />
            </div>
            <div class="certificate">{{ item.name }}</div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="line" style="padding: 0 10px">
        <div v-if="$isMobile" style="border-bottom: 1px solid #f1f1f1; margin-top: 5px"></div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="card" style="margin-bottom: 0px" ref="contact">
      <span>联系我们</span>
    </div>
    <div class="contact">
      <div class="contact_content">
        <div class="contact_title">
          <div class="title">
            广州市广农数链信息科技有限公司
          </div>
          <div class="text">
            <p>地址：广州市黄埔区科学城绿地中央广场C1栋5楼</p>
            <p>电话：020-82258374、37889287</p>
            <p>企业邮箱：service@suzi.com.cn</p>
            <p class="pcdisp">官网：https://tevv.com.cn</p>
            <p class="appdisp">官网：tevv.com.cn</p>
            <p style="font-weight: 700;" class="pcdisp">黄经理：18925127688</p>
          </div>
        </div>
        <div class="contact_adress">
          <img src="@/assets/images/companyProfile/address.png" @click="onAddressImgClick">

          <viewer :images="imgs" v-show="showBigAddressImg">
            <img ref="bigAddressImg" src="@/assets/images/companyProfile/address_big.png" alt="">
          </viewer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPublishListByType } from '@/api/officialWebsite'

const companyAgeRange = ['2017', '2018', '2019', '2022', '2023']

export default {
  name: 'about',
  data () {
    return {
      showBigAddressImg: false,
      imgs: [
        require('@/assets/images/companyProfile/address_big.png')
      ],
      tabKey: '0',
      tabs: [
        {
          title: '企业简介',
          list: [],
          loadmore: false
        },
        {
          title: '企业文化',
          list: [],
          loadmore: false
        },
        {
          title: '发展历程',
          list: [],
          loadmore: false
        },
        {
          title: '获得荣誉',
          list: [],
          loadmore: false
        },
        // {
        //   title: '人才招聘',
        //   list: [],
        //   loadmore: false
        // },
        {
          title: '联系我们',
          list: [],
          loadmore: false
        }
      ],
      updateKey: +new Date(),
      swiperOptionOther: {
        observer: true,
        slidesPerView: 3.5,
        spaceBetween: 43,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置自动轮播
        autoplay: {
          delay: 4000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      },
      publishData: {}
    }
  },
  created () {
    this.initData()
    this.watchRouteName()
  },
  watch: {
    // 监听,当路由发生变化的时候执行
    $route: {
      handler: function (val, oldVal) {
        const type = val.query.type ? val.query.type : val.name
        if (type) {
          window.scrollTo(0, 0)
          if (type === 'companyProfile') {
            this.mappingPlatformType(0)
          } else if (type === 'corporateCulture') {
            this.mappingPlatformType(1)
          } else if (type === 'developmentHistory') {
            this.mappingPlatformType(2)
          } else if (type === 'enterpriseHonor') {
            this.mappingPlatformType(3)
          } else {
            this.mappingPlatformType(4)
          }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted () {
    // 从其它非导航菜单跳转过来
    const type = this.$route.query.type || ''
    if (type) {
      window.scrollTo(0, 0)
      if (type === 'abstract') {
        this.mappingPlatformType(0)
      } else if (type === 'culture') {
        this.mappingPlatformType(1)
      } else if (type === 'course') {
        this.mappingPlatformType(2)
      } else if (type === 'achievements') {
        this.mappingPlatformType(3)
      } else {
        this.mappingPlatformType(4)
      }
    }

    this.resetBannerNum()
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    onAddressImgClick () {
      this.$refs.bigAddressImg.click()
    },
    watchRouteName () {
      this.$watch('$route.meta.title', title => {
        // 当前导航菜单切换滚动视图
        this.toggleNavMenuScrollView(title)
      })
    },
    toggleNavMenuScrollView (menuName) {
      window.scrollTo(0, 0)
      setTimeout(() => {
        if (menuName === '企业简介') {
          this.$refs.abstract.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else if (menuName === '企业文化') {
          this.$refs.culture.scrollIntoView({ behavior: 'smooth' })
        } else if (menuName === '发展历程') {
          this.$refs.course.scrollIntoView({ behavior: 'smooth' })
        } else if (menuName === '获得荣誉') {
          this.$refs.achievements.scrollIntoView({ behavior: 'smooth' })
        } else if (menuName === '联系我们') {
          this.$refs.contact.scrollIntoView({ behavior: 'smooth' })
        }
      }, 30)
    },
    handleCheck (i) {
      this.tabKey = i
      // 点击 tab 滚动视图
      this.mappingPlatformType(i)
    },
    mappingPlatformType (i) {
      const fn = {
        0: () => this.scrollView('abstract'), // 企业简介
        1: () => this.scrollView('culture'), // 企业文化
        2: () => this.scrollView('course'), // 发展历程
        3: () => this.scrollView('achievements'), // 获得荣誉
        4: () => this.scrollView('contact') // 联系我们
      }[i]
      fn && fn()
    },
    mappingMetaTitle (title) {
      if (title) {
        const fn = {
          企业简介: () => this.scrollView('abstract'), // 企业简介
          企业文化: () => this.scrollView('culture'), // 企业文化
          发展历程: () => this.scrollView('course'), // 发展历程
          获得荣誉: () => this.scrollView('achievements'), // 获得荣誉
          联系我们: () => this.scrollView('contact') // 联系我们
        }[title]

        fn && fn()
      }
    },
    async initData () {
      const [publishData] = await Promise.all([
        getPublishListByType({
          publish_type: ['12', '13', '14'] // 12:企业文化  13:发展历程  14:获得荣誉  18:合作企业
        })
      ])

      this.publishData = publishData || {}
      this.publishData['13'] = this.publishData['13'].map((v, i) => {
        return {
          ...v,
          date: companyAgeRange[i]
        }
      })

      // 从其它导航菜单跳转过来滚动视图
      const { title } = this.$route.meta
      setTimeout(() => {
        this.mappingMetaTitle(title)
      }, 100)
    },
    scrollView (tabRef) {
      setTimeout(() => {
        this.$refs[tabRef].scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    // 页面大小改变
    resizePage () {
      this.resetBannerNum()
    },
    // 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 992) {
        this.swiperOptionOther.slidesPerView = 3.5
      } else if (window.document.body.clientWidth >= 768) {
        this.swiperOptionOther.slidesPerView = 3
      } else if (window.document.body.clientWidth >= 576) {
        this.swiperOptionOther.slidesPerView = 2
      } else {
        this.swiperOptionOther.slidesPerView = 1
      }
      this.updateKey = +new Date()
    }
  }
}
</script>

<style scoped lang="scss">
// 隐藏手机端要显示的东西
.mobile-abstract{
  display: none !important;
}
.mobile-culture{
  display: none !important;
}
.mobile-li{
  display: none !important;
}

.about{
  margin-top: 68px;
  padding: 45px 0px;
  box-sizing: border-box;
  color: #333333;
  .card{
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    span{
      display: inline-block;
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      padding: 88px 0px 14px 0px;
      color: #333333;
      border-bottom: 2px solid #00A0AC;
      box-sizing: border-box;
    }
  }
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #333334;
  }
  .tabs{
    display: flex;
    justify-content: center;
    margin-top: 85px;
    width: 100%;
    border-bottom: 1px solid #cccccc;
    ul{
      display: flex;
      li{
        margin: 0px 30px;
        padding-bottom: 30px;
        font-size: 24px;
        color: #333334;
        box-sizing: border-box;
        cursor:pointer;
      }
    }
  }
  .abstract{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #EEEEEE;
    box-sizing: border-box;
    .abstract_content{
      position: relative;
      width: 1440px;
      .tip{
        position: absolute;
        top: -28px;
        left: 0px;
        width: 324px;
        height: 254px;
        img{
          width: 324px;
          height: 254px;
        }
      }
      .abstract_info{
        // padding-right: 68px;
        padding-bottom: 47px;
        box-sizing: border-box;
        // display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #cecdcd;
        .abstract_info_header{
          width: 100%;
          height: 254px;
          margin: 0 auto;
          // display: flex;
          .abstract_info_img{
            width: 330px;
          }
          .header{
            margin-left: 95px;
            flex: 1;
            margin-top: 78px;
            .title{
              font-size: 30px;
              font-weight: 700;
              margin-bottom: 30px;
            }
            .header-main{
              font-size: 20px;
              color: #333333;
              line-height: 40px;
            }
            .header-main p:nth-child(1){
              text-indent: 2em; /* 设置缩进的大小，这里是 2 个em单位 */
              margin-top: -8px;
            }
          }
        }
        .abstract_info_footer{
          display: flex;
          .footer_left{
            display: flex;
            flex-direction: column;
            font-size: 20px;
            line-height: 40px;
            div{
              text-indent: 2em; /* 设置缩进的大小，这里是 2 个em单位 */
            }
            div:nth-child(1){
              padding: 40px 56px 40px 50px;
            }
            div:nth-child(2){
              padding: 0 56px 0 53px;
            }
          }
          .footer_img{
            width: 863px;
            height: 425px;
          }
        }
        .abstract_info_left{
          padding-top: 279px;
          width: 820px;
          img{
            width: 820px;
            height: auto;
          }
        }
        .abstract_info_right{
          padding-top: 47px;
          box-sizing: border-box;
          width: 458px;
          .abstract_info_right_title{
            margin-bottom: 30px;
            font-size: 30px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: justifyLeft;
            color: #333333;
            line-height: 43px;
          }
          .abstract_info_right_title1{
            margin: 54px 0px 20px;
            font-size: 30px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: center;
            color: #00a0ac;
            line-height: 36px;
          }
          .abstract_info_right_text{
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: justifyLeft;
            color: #333333;
            line-height: 30px;
          }
          img{
            margin: 40px 0px 25px;
          }
        }
      }
      .abstract_img{
        padding: 30px 0px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cecdcd;
        img:nth-child(1){
          width: 276px;
        }
        img:nth-child(2){
          width: 316px;
        }
        img:nth-child(3){
          width: 291px;
        }
        img:nth-child(4){
          width: 165px;
        }
        img:nth-child(5){
          width: 150px;
        }
        img:nth-child(6){
          width: 162px;
        }
        img{
          height: auto;
          object-fit: none !important;
        }
      }
      .abstract_ul{
        padding: 46px 0px 67px 111px;
        display: flex;
        ul{
          li{
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: justifyLeft;
            color: #333333;
            line-height: 40px;
            letter-spacing: 0.9px;
          }
        }
        ul:nth-child(1){
          margin-right: 362px;
        }
      }
    }
  }

  .culture{
    display: flex;
    justify-content: center;
    width: 100%;
    .culture_content{
      width: 1440px;
      .title{
        margin-bottom: 25px;
        font-size: 42px;
        font-weight: 700;
        color: #ffffff;
      }
      .text{
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
      }
      .top{
        margin-bottom: 13px;
        width: 100%;
        height: 221px;
        ul{
          display: flex;
          height: 100%;
          li{
            margin-right: 11px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
          li:nth-child(1){
            width: 424px;
            height: 221px;
            background: #00a0ac;
          }
          li:nth-child(2){
            width: 236px;
            height: 221px;
            img{
              width: 236px;
              height: 221px;
            }
          }
          li:nth-child(3){
            width: 167px;
            height: 221px;
            background: #ababab;
            img{
              width: 48px;
              height: 53px;
            }
          }
          li:nth-child(4){
            width: 424px;
            height: 221px;
            background: #3bae95;
          }
        }
      }
      .bottom{
        width: 100%;
        height: 221px;
        ul{
          display: flex;
          justify-content: flex-end;
          height: 100%;
          li{
            margin-right: 11px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
          li:nth-child(1){
            width: 264px;
            height: 221px;
            background: #3bae95;
          }
          li:nth-child(2){
            width: 220px;
            height: 221px;
            img{
              width: 220px;
              height: 221px;
            }
          }
          li:nth-child(3){
            width: 353px;
            height: 221px;
            background: #f6c457;
          }
          li:nth-child(4){
            width: 351px;
            height: 221px;
            background: #00a0ac;
          }
        }
      }
    }
  }
  .course{
    display: flex;
    justify-content: center;
    width: 100%;
    .course_content{
      padding-left: 136px;
      width: 1440px;
      ul{
        li{
          display: flex;
          align-items: center;
          margin-bottom: 70px;
          .icon{
            position: relative;
            width: 28px;
            height: 29px;
            background: #f6f6f6;
            border: 2px solid #00a0ac;
            border-radius: 50%;
          }
          .icon::after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: -90px;
            transform: translate(-50%,0);
            width: 2px;
            height: 90px;
            background-color: #00A0AC;
          }
          .year{
            margin: 0px 52px 0px 22px;
            font-size: 30px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: justifyLeft;
            color: #00a0ac;
            line-height: 24px;
          }
          .title{
            margin-right: 30px;
            font-size: 28px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
            line-height: 31px;
          }
          .text{
            font-size: 28px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 500;
            text-align: left;
            color: #333333;
            line-height: 31px;
            flex: 1;
          }
        }
        li:last-child{
          .icon::after{
            bottom: -50px;
            height: 50px;
          }
        }
      }
    }
  }
  .achievements {
    margin: 0 auto;
    margin-top: 27px;
    padding-top: 60px;
    width: 1440px;
    border-top: 1px solid #e2e2e2;

    .achievements-list {
      box-sizing: border-box;
      height: 400px;
      .achievements-item {
        padding: 20px 20px 20px 0px;
        width: 395px;
        height: 284px;
        box-sizing: border-box;
        .img {
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .certificate {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          color: #505355;
          padding-top: 18px;
        }
      }
    }
  }
  .contact{
    display: flex;
    justify-content: center;
    width: 100%;
    .contact_content{
      margin-top: 33px;
      padding-top: 77px;
      display: flex;
      width: 1440px;
      border-top: 1px solid #e2e2e2;
      box-sizing: border-box;
      .contact_title{
        padding-right: 86px;
        height: 326px;
        flex: 1;
        box-sizing: border-box;
        .title{
          font-size: 32px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          text-align: justifyLeft;
          color: #0c0307;
          line-height: 48px;
        }
        .text{
          margin-top: 20px;
          font-size: 24px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: justifyLeft;
          color: #0c0307;
          line-height: 48px;
        }
      }
      .contact_adress{
        width: 815px;
        height: 326px;
        cursor: pointer;

        img{
          width: 815px;
          height: 326px;
        }
      }
    }
  }
}
.active_tabs{
  font-weight: 700;
  border-bottom: 2px solid #00A0AC;
}

::v-deep .achievements .swiper-pagination{
  .swiper-pagination-bullet {
    margin: 0px 5px;
    background: hsl(0, 2%, 71%);
  }
  .swiper-pagination-bullet-active{
    background-color: #a3a3a3;
  }
}
</style>

<style scoped lang='scss'>
// 移动端 样式
@import './media';
</style>
